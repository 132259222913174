<template>
  <v-card>
    <validation-observer ref="obs" v-slot="{ errors, invalid, valid }">
      <v-card-text>
        <v-card class="mb-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("order details") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  vid="product_description"
                  :name="$t('product description')"
                  rules="max:100"
                  v-slot="{ errors, valid, dirty, classes }"
                >
                  <v-text-field
                    v-model="productDescription"
                    name="product_description"
                    :label="$t('product description')"
                    :hint="$t('product description appears on invoice')"
                    :error-messages="errors"
                    :success="dirty && valid"
                    :class="classes"
                    counter="100"
                    :clearable="!isMobile"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="orderNote"
                  :label="$t('order note')"
                  maxlength="1024"
                  rows="1"
                  :clearable="!isMobile"
                  counter
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <email-list-input
                  v-model="orderEmail"
                  :label="$t('confirmation email')"
                  max-length="1024"
                  multiple
                />
              </v-col>
              <v-col cols="6">
                <language-select
                  v-model="orderLang"
                  :label="$t('delivery receipt language')"
                  :codes="['en', 'de']"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-4" outlined>
          <v-toolbar color="titleBar" dark flat dense>
            <v-toolbar-title>{{ $t("delivery address") }}</v-toolbar-title>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="deleteDeliveryAddress"
                  :disabled="
                    loading ||
                    loadingPreview ||
                    loadingReceipt ||
                    !differentDeliveryAddress
                  "
                  icon
                >
                  <v-icon>mdi-delete-sweep-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete delivery address") }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="previewReceipt"
                  :disabled="loading || loadingPreview || invalid"
                  :loading="loadingPreview"
                  icon
                >
                  <v-icon>$PdfDownload</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("preview delivery receipt") }}</span>
            </v-tooltip>

            <v-dialog v-model="editReceipt" width="80%">
              <template v-slot:activator="{ on }">
                <v-tooltip top v-on="on">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      @click="
                        editReceipt = true;
                        getReceipt();
                      "
                      :disabled="loading || loadingReceipt || invalid"
                      :loading="loadingReceipt"
                      icon
                    >
                      <v-icon>mdi-file-document-edit</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("edit delivery receipt") }}</span>
                </v-tooltip>
              </template>
              <hardware-shop-delivery-receipt
                :receipt="deliveryReceipt"
                :loading="loadingReceipt"
                @receiptCanceled="editReceipt = false"
                @receiptReset="
                  deliveryReceipt = '';
                  getReceipt();
                "
                @receiptSaved="
                  deliveryReceipt = $event;
                  editReceipt = false;
                "
              />
            </v-dialog>
          </v-toolbar>

          <v-card-actions>
            <v-switch
              v-model="differentDeliveryAddress"
              :label="$t('change delivery address')"
            />
          </v-card-actions>
          <v-card-text>
            <address-names
              v-model="deliveryAddress"
              company-max-length="100"
              first-name-max-length="100"
              last-name-max-length="100"
              :disabled="!differentDeliveryAddress"
              name-prefix="delivery_address."
            />
          </v-card-text>
        </v-card>

        <v-card class="mb-2" outlined>
          <v-card-text>
            <address-postal
              v-model="deliveryAddress"
              additional-max-length="255"
              :zip-max-length="10"
              :city-max-length="100"
              :street-max-length="100"
              street-number-name="house_number"
              :street-number-max-length="10"
              :disabled="!differentDeliveryAddress"
              name-prefix="delivery_address."
            />
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$router.go(-1)" :disabled="loading" text>
          {{ $t("back") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="commitOrder()"
          :disabled="
            loading || invalid || $store.getters.isReadOnly || itemsInCart == 0
          "
          :loading="loading"
          text
        >
          <v-icon class="mr-2">mdi-cash-register</v-icon>
          {{ $t("order") }}
        </v-btn>
      </v-card-actions>
    </validation-observer>
  </v-card>
</template>

<script>
import downloadFile from "@/utils/mixins/downloadFile";
import AddressContacts from "@/components/basics/AddressContacts";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressNames from "@/components/basics/AddressNames";
import EmailListInput from "@/components/basics/EmailListInput";
import LanguageSelect from "@/components/basics/LanguageSelect";
import HardwareShopDeliveryReceipt from "./HardwareShopDeliveryReceipt";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("hardwareShop");

export default {
  name: "HardwareShopOrder",
  mixins: [downloadFile, showErrors, isMobile],
  components: {
    AddressContacts,
    AddressPostal,
    AddressNames,
    EmailListInput,
    LanguageSelect,
    HardwareShopDeliveryReceipt,
  },
  data: () => ({
    loading: false,
    loadingReceipt: false,
    loadingPreview: false,
    differentDeliveryAddress: false,
    deliveryReceipt: "",
    editReceipt: false,
    orderEmail: "",
    orderNote: "",
    orderLang: "",
    deliveryAddress: {
      is_corporate: null,
      company: "",
      first_name: "",
      salutation: "n",
      additional: "",
      street: "",
      house_number: "",
      zip: "",
      city: "",
      phone: "",
      mobile: "",
      email: "",
    },
    productDescription: "",
  }),
  computed: {
    ...mapState(["items", "card", "reference"]),
    ...mapGetters([
      "itemsInCart",
      "cartItems",
      "priceMonthly",
      "priceFix",
      "shippingItem",
    ]),
  },
  watch: {
    cartItems() {
      // reset if items in cart has been changed
      this.deliveryReceipt = "";
    },
    reference: {
      immediate: true,
      handler(value) {
        if (value != null) this.differentDeliveryAddress = true;
      },
    },
    orderEmail: {
      immediate: true,
      handler(value) {
        this.orderEmail = value || this.$store.getters.deliveryEmail;
      },
    },
    orderLang: {
      immediate: true,
      handler(value) {
        this.orderLang = value || this.$store.state.locale;
      },
    },
    differentDeliveryAddress: {
      immediate: true,
      handler(value) {
        if (!value) this.readDefaults();
      },
    },
  },
  methods: {
    ...mapMutations(["resetCart", "setReference", "setLoaded"]),
    readDefaults() {
      if (this.$store.getters.isReady) {
        var that = this;
        const addr = this.$store.getters.defaultDeliveryAddress || {};

        if (this.reference == null) {
          // either use customer address
          this.deliveryAddress = { ...addr };
          this.deliveryAddress.house_number =
            addr.house_number || addr.street_number;
          this.deliveryAddress.zip = addr.zip || addr.zip_code;
        } else {
          // or reference order install address from validate
          this.loading = true;
          this.$http
            .post("shop/hardware/validate", {
              reference: this.reference,
              orders: [],
            })
            .then((response) => {
              const addr = response.data.delivery_address;
              that.deliveryAddress = { ...addr };
              that.deliveryAddress.house_number =
                addr.house_number || addr.street_number;
              that.deliveryAddress.zip = addr.zip || addr.zip_code;
            })
            .catch((error) => {})
            .finally(function () {
              that.loading = false;
            });
        }
      }
    },
    getRequestData() {
      // fix address
      this.deliveryAddress.email =
        (this.deliveryAddress.email || "").trim() || null;
      this.deliveryAddress.phone =
        (this.deliveryAddress.phone || "").trim() || null;
      this.deliveryAddress.mobile =
        (this.deliveryAddress.mobile || "").trim() || null;

      // quick hack to solve issue with company name and is_corporate
      var deliveryAddress = { ...this.deliveryAddress };
      deliveryAddress.company = deliveryAddress.is_corporate
        ? deliveryAddress.company || null
        : "";

      var params = {
        orders: this.cartItems.map(function (item) {
          return {
            variant: item.priceItem.id,
            quantity: item.quantity,
          };
        }),
        reference: (this.reference || "").trim() || null,
        delivery_receipt: (this.deliveryReceipt || "").trim() || null,
        delivery_address: deliveryAddress,
        email: this.orderEmail,
        note: this.orderNote,
        language: this.orderLang,
        product_description: this.productDescription || null,
      };
      if (this.shippingItem != null)
        params.orders.push({
          variant: this.shippingItem.id,
          quantity: 1,
        });
      return params;
    },
    previewReceipt() {
      var that = this;
      var data = this.getRequestData();
      this.loadingPreview = true;
      this.$http
        .post("shop/hardware/validate", data)
        .then((response) => {
          try {
            this.downloadFile(
              "shop/hardware/validate?format=pdf",
              "post",
              data,
              this.$i18n.t("delivery-receipt.pdf"),
              null,
              function () {
                that.loadingPreview = false;
              }
            );
          } catch (error) {
            that.loadingPreview = false;
            console.log(error);
          }
        })
        .catch((error) => {
          that.loadingPreview = false;
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        });
    },
    deleteDeliveryAddress() {
      this.deliveryAddress = {
        is_corporate: null,
        company: "",
        first_name: "",
        last_name: "",
        salutation: "n",
        additional: "",
        street: "",
        house_number: "",
        zip: "",
        city: "",
        phone: "",
        mobile: "",
        email: "",
      };
    },
    getReceipt() {
      if (this.deliveryReceipt === null || this.deliveryReceipt.trim() === "") {
        var that = this;
        var data = this.getRequestData();
        this.loadingReceipt = true;
        this.$http
          .post("shop/hardware/validate", data)
          .then((response) => {
            that.deliveryReceipt = response.data.delivery_receipt;
          })
          .catch((error) => {
            that.$refs.obs.setErrors(error.data);
            that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          })
          .finally(function () {
            that.loadingReceipt = false;
          });
      }
    },
    commitOrder() {
      var that = this;
      var data = this.getRequestData();
      this.loading = true;
      this.$http
        .post("shop/hardware/order", data)
        .then((response) => {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          // rest cart, delete reference and force reload hardware items
          that.resetCart();
          that.setReference(null);
          that.setLoaded(false);
          that.$route.meta.keepAlive = false; // drop component from cache
          that.$router.push({ name: "hardware-list" });
          that.$route.meta.keepAlive = true;
        })
        .catch((error) => {
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  mounted() {
    this.readDefaults();
  },
};
</script>

<i18n>
{
  "en": {
    "order details": "Order details",
    "back": "Back",
    "order contact": "Order contact",
    "delivery address": "Delivery address",
    "order": "Order",
    "email": "Email",
    "confirmation email": "Confirmation email",
    "order note": "Own note for order confirmation",
    "phone": "Phone",
    "order contact phone number": "Order contact phone number",
    "mobile": "Mobile",
    "order contact mobile number": "Order contact mobile number",
    "product description": "Product description",
    "product description appears on invoice": "product description appears on invoice",
    "company name": "Company name",
    "salutation": "Salutation",
    "first name": "First name",
    "last name": "Last name",
    "additional": "Additional",
    "none": "None",
    "Mr.": "Mr.",
    "Ms.": "Ms.",
    "change delivery address": "Change delivery address",
    "preview delivery receipt": "Preview delivery receipt",
    "edit delivery receipt": "Edit delivery receipt",
    "delivery contact": "Delivery contact",
    "delivery-receipt.pdf": "Delivery-receipt.pdf",
    "notifyMsg": "Your order has been successfully committed.",
    "delete delivery address": "Delete delivery address",
    "delivery receipt language": "Delivery receipt language"
  },
  "de": {
    "order details": "Bestelldetails",
    "back": "Zurück",
    "order contact": "Bestellkontakt",
    "delivery address": "Lieferadresse",
    "order": "Bestellen",
    "email": "Email",
    "confirmation email": "Bestätigungs Email",
    "order note": "Eigene Notiz für Bestellbestätigung",
    "phone": "Telefon",
    "order contact phone number": "Bestellkontakt Telefonnummer",
    "mobile": "Mobil",
    "order contact mobile number": "Bestellkontakt Mobilnummer",
    "product description": "Produktbeschreibung",
    "product description appears on invoice": "Produktbeschreibung erscheint auf der Rechnung",
    "company name": "Firmenname",
    "salutation": "Anrede",
    "first name": "Vorname",
    "last name": "Nachname",
    "additional": "Addresszusatz",
    "none": "keine",
    "Mr.": "Herr",
    "Ms.": "Frau",
    "change delivery address": "Lieferadresse ändern",
    "preview delivery receipt": "Lieferschein Vorschau",
    "edit delivery receipt": "Lieferschein bearbeiten",
    "delivery contact": "Lieferkontakt",
    "delivery-receipt.pdf": "Lieferschein.pdf",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt.",
    "delete delivery address": "lösche Lieferadresse",
    "delivery receipt language": "Lieferschein Sprache"
  }
}
</i18n>
